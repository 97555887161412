"use client";

import React, {
  useState,
  Suspense,
  useMemo,
  createContext,
  useContext,
} from "react";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";

import styles from "./layout.module.scss";
import Components from "@/components";
import utils from "@/utils";

const OpenContext = createContext();

export function useOpen() {
  return useContext(OpenContext);
}

const Courses = ({ children }) => {
  const user = useSelector((state) => state?.user?.users);
  const points = useSelector((state) => state.points);
  const pathname = usePathname();

  const [open, setOpen] = useState(false);

  const setCPoints = useMemo(() => {
    if (user?.email) {
      const res = utils.calculateCipherPoints(points);
      let formatString = "";
      if (res < 10) formatString = "0" + res;
      else formatString = res;
      return formatString;
    } else {
      return "0";
    }
  }, [user, points]);

  return (
    <>
      <Components.Navbar
        watchPoint={setCPoints}
        onClose={() => setOpen(false)}
        onSideCall={
          [
            "/",
            "/alumni",
            "/about-us",
            "/payment",
            "/privacy",
            "/support-us",
            "/terms-and-condition",
          ].includes(pathname)
            ? null
            : () => setOpen(!open)
        }
        className={
          [
            "/",
            "/alumni",
            "/about-us",
            "/payment",
            "/privacy",
            "/support-us",
            "/terms-and-condition",
          ].includes(pathname)
            ? styles["landing-sidebar"]
            : styles["general-sidebar"]
        }
      />
      <main className={styles.main}>
        <OpenContext.Provider value={{ open }}>
          <Suspense fallback={<Components.PageLoader />}>{children}</Suspense>
        </OpenContext.Provider>
      </main>
    </>
  );
};

export default Courses;
